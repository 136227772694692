import React from 'react'
import { Link } from 'react-router-dom'

const MenuSection = ({sectionActive}) => {
    
    const menuOptions = [
        {name: "About", src: '', id: 'about'},
        {name: "Contacto", src: 'contacto', id: 'contacto'}
    ]

    const renderNavigationMenu = (optionMenu, sectionActive) => {
        const {name, src, id} = optionMenu
        const classExtra = (id === sectionActive)? "sectionOn" : ''

        return (
            <li key={`list${id}`}>
                <Link key={id} to={`/${src}`} className={`section ${classExtra}`}>{name}</Link>
            </li>
        )
    }

    return (
        <div id="splashInner">
      
            <h1>Sergi Giraldo</h1>
            <h2>Full Stack Developer<br />& Project Management</h2>
            
            <ul>
                {
                    menuOptions.map(option => renderNavigationMenu(option, sectionActive))
                }
            </ul>
            
            <div id="promsite">
            <img src="/images/cdmon.jpg" alt="CDMON" width="113" height="43" border="0" vspace="15" />
            <a href="https://www.cdmon.com/" target="_blank" rel="noreferrer">Hosting by <strong>cdmon</strong></a>
            </div>

        </div>
    )
}

export default MenuSection
