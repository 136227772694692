import React from 'react'
import { 
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import MainPage from './pages/MainPage'
import AboutPage from './pages/AboutPage'
import WorkPage from './pages/WorkPage'
import ContactPage from './pages/ContactPage'

const resourcesWork = [
    {
        id: 'sisterland', 
        title: 'Bennetton Sisterland', 
        thumbnail: 'sisterland_thumb.jpg', 
        desc: 'Digital Press Dossier para el lanzamiento de Sisterland, nueva fragancia de Benetton.', 
        url: '', 
        tags: ['Front-end','Delirium Advertising'],
        contenido: [
            {label: 'Screenshot #01', type: 'video', source: 'sisterland01'},
            {label: 'Screenshot #02', type: 'video', source: 'sisterland02'}
        ]
    },
    {
        id: 'ohzono', 
        title: 'Oh!Zono', 
        thumbnail: 'ohzono.jpg', 
        desc: 'Landing captadora de leads para el lanzamiento del nuevo producto de Grupo Corsa.', 
        url: 'https://ohzono.es', 
        tags: ['Front-end','Grupo Corsa'],
        contenido: [
            {label: 'Video screenshot #01', type: 'video', source: 'ohzono_video'},
            {label: '', type: 'image', source: 'ohzono.jpg'},
            {label: '', type: 'image', source: 'ohzono2.jpg'},
            {label: '', type: 'image', source: 'ohzono3.jpg'},
        ]
    },
    {
        id: 'erdaide', 
        title: 'Método Erdaide', 
        thumbnail: 'erdaide_thumb.jpg', 
        desc: '', 
        url: 'http://ionerdaide.com', 
        tags: ['Front-end','Wordpress','Delirium Advertising'],
        contenido: [
            {label: '', type: 'image', source: 'erdaide_thumb.jpg'},
            {label: '', type: 'image', source: 'erdaide02.jpg'},
            {label: 'Video screenshot #01', type: 'video', source: 'erdaide_video'},
        ]
    },
    {
        id: 'together', 
        title: 'Bennetton United Deams Together', 
        thumbnail: 'together.jpg', 
        desc: 'Digital Press Dossier para el lanzamiento de United Dreams Together, nueva fragancia de Benetton.', 
        url: '', 
        tags: ['Front-end','Delirium Advertising'],
        contenido: [
            {label: 'Screenshot #01', type: 'video', source: 'together_video'},
            {label: 'Screenshot #02', type: 'video', source: 'together_video2'},
        ]
    },
    {
        id: 'calculadora', 
        title: 'La calculadora de Corsa', 
        thumbnail: 'calculadora1.jpg', 
        desc: 'Landing generación de leads para Grupo Corsa. Calculadora en tiempo real del consumo de agua, plásticos y gasto económico relacionado con el uso de agua de un hogar.', 
        url: 'https://lacalculadoradecorsa.com/', 
        tags: ['Front-end','Grupo Corsa'],
        contenido: [
            {label: 'Video screenshot #01', type: 'video', source: 'calculadora_video'},
            {label: 'Video screenshot #02', type: 'video', source: 'calculadora_video2'},
            {label: '', type: 'image', source: 'calculadora1.jpg'},
            {label: '', type: 'image', source: 'calculadora2.jpg'},
            {label: '', type: 'image', source: 'calculadora3.jpg'},
        ]
    },
    {
        id: 'resource', 
        title: 'ReSource Plastic', 
        thumbnail: 'resource.jpg', 
        desc: 'Maquetación html y animación principal para el proyecto ReSource Plastic, una iniciativa de WWF para la transición medioambiental de grande empresas.', 
        url: 'https://resource-plastic.com', 
        tags: ['Front-end','OneBigRobot'],
        contenido: [
            {label: '', type: 'image', source: 'resource.jpg'},
            {label: 'Video screenshot #01', type: 'video', source: 'resource_video'},
        ]
    },
    {
        id: 'obr2018', 
        title: 'OneBigRobot', 
        thumbnail: 'obr.jpg', 
        desc: 'Rediseño del portfolio interactivo de la agencia OneBigrobot, bajo el uso de CraftCMS y Yii. Elaboración dinámica de case studies.', 
        url: 'https://www.onebigrobot.com/', 
        tags: ['Front-end','CraftCMS','OneBigRobot'],
        contenido: [
            {label: 'Video screenshot #01', type: 'video', source: 'obr_video'},
            {label: 'Video screenshot #02', type: 'video', source: 'obr_video2'},
            {label: '', type: 'image', source: 'obr.jpg'},
            {label: '', type: 'image', source: 'obr2.jpg'},
            {label: '', type: 'image', source: 'obr3.jpg'},
        ]
    },
    {
        id: 'decs', 
        title: 'Creative Decoding Tool', 
        thumbnail: 'decs.jpg', 
        desc: 'La Creative Decoding Tool es una herramienta online que permite mapear las competencias creativas de estudiantes y profesionales de diseño a través de un cuestionario.', 
        url: 'http://www.decsproject.net/creative-decoding-tool', 
        tags: ['Front-end','Back-end','Wordpress','The Story Tellers'],
        contenido: [
            {label: '', type: 'image', source: 'decs.jpg'},
        ]
    },
    {
        id: 'ngp', 
        title: 'The Northern Great Plains', 
        thumbnail: 'ngp.jpg', 
        desc: 'Proyecto para mostrar la labor de WWF para la protección de la biodiversidad en las grandes llanuras de Norte America. Mediante custom CMS, se pueden desarrollar case studies dinámicos.', 
        url: 'https://www.storyofwater.org/', 
        tags: ['Front-end','Custom CMS','OneBigRobot'],
        contenido: [
            {label: 'Video screenshot #01', type: 'video', source: 'ngp_video'},
            {label: '', type: 'image', source: 'ngp.jpg'},
            {label: '', type: 'image', source: 'ngp2.jpg'},
        ]
    },
    {
        id: 'vaccines', 
        title: 'Vaccines Work', 
        thumbnail: 'vaccines.jpg', 
        desc: 'Desarrollo de un quiz interactivo para la semana de la inmunización mundial promovida por la Organización Mundial de la Salud, sobre la importancia de la vacunación.', 
        url: 'http://www.onebigrobot.com/beta/_oms-vaccines', 
        tags: ['Front-end', 'OneBigRobot'],
        contenido: [
            {label: '', type: 'image', source: 'vaccines.jpg'},
            {label: '', type: 'image', source: 'vaccines2.jpg'},
            {label: '', type: 'image', source: 'vaccines3.jpg'},
        ]
    },
    {
        id: 'wapo', 
        title: 'Why we fall for political spin', 
        thumbnail: 'wapo.jpg', 
        decs: 'Desarrollo de un quiz interactivo, mediante PixiJs, sobre la influencia en el sesgo cognitivo mediante la política. ', 
        url: 'http://onebigrobot.com/beta/bias/', 
        tags: ['Front-end', 'PixiJs','OneBigRobot'],
        contenido: [
            {label: 'Video screenshot #01', type: 'video', source: 'wapo_video'},
            {label: '', type: 'image', source: 'wapo.jpg'},
            {label: '', type: 'image', source: 'wapo2.jpg'},
            {label: '', type: 'image', source: 'wapo3.jpg'},
        ]
    }
]

const App = () => {

  return(
    <Router>
        <Route render={({ location }) => (
            <AnimatePresence initial={false} exitBeforeEnter>
            <Switch location={location} key={location.key}>
                <Route exact path="/">
                    <AboutPage></AboutPage>
                </Route>

                <Route exact path="/contacto">
                    <ContactPage></ContactPage>
                </Route>
            </Switch>
            </AnimatePresence>
        )} />
        
    </Router>
  )
}

export default App;
