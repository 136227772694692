import React from 'react'
import { motion } from 'framer-motion'

import MenuSection from '../components/MenuSection'

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] }

const ContactPage = () => {
    return (
        <div id="splash">
      
        <MenuSection sectionActive="contacto" />

        <motion.div id="project" className="contacto" initial={{opacity: 0, y:20}} animate={{opacity:1, y:0}} exit={{opacity: 0, y:20}} transition={transition}>
            <div className="desc desContacto">
                Sergi Giraldo<br />
                Badalona, Barcelona<br />
                +34 607 24 58 42<br />
                Mail <a href="mailto:info@sgiraldo.com">info@sgiraldo.com</a><br />
                Linkedin <a href="https://www.linkedin.com/in/sergi-giraldo-09087617" target="_blank" rel="noreferrer">Profile</a><br />
                Twitter <a href="https://twitter.com/thisissergei" target="_blank" rel="noreferrer">thisissergei</a><br />
                <br /><br />
            </div>
        </motion.div>

        <br /><br />
        </div>
    )
}

export default ContactPage